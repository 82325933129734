<template>
  <div>

    <div style="background: white; padding: 10px; border-radius: 10px;">
      <h3>Паспорт проекта</h3>
      <div v-if="project.id">


        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Название проекта</b>
          <p>Отражает основную идею проекта </p>
          <v-text-field rounded style="border-radius: 10px; border-color: #efefef;" v-model="project.title"
                        :readonly="readOnly" outlined/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Сроки выполнения проекта</b>
          <p>Сроки, в которые выполняется проект</p>
          <v-text-field placeholder="например, март 2022 - январь 2022" rounded
                        style="border-radius: 10px; border-color: #efefef;" v-model="project.deadlines"
                        :readonly="readOnly" outlined/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Цель проекта</b>
          <p>Что будет достигнуто? Когда? Как? Как вы измерите уровень достижения результата? </p>
          <v-textarea rounded style="border-radius: 10px; border-color: #efefef;"
                      v-model="project.participation_purpose" :readonly="readOnly" outlined/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Проблема проекта</b>
          <p>Сложности, затруднения, препятствия, которые будут преодолены с помощью проекта </p>
          <v-textarea rounded style="border-radius: 10px; border-color: #efefef;" v-model="project.problem"
                      :readonly="readOnly" outlined/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Тип проекта</b>
          <v-autocomplete rounded style="border-radius: 10px; border-color: #efefef;" v-model="project.project_type_id"
                          :items="$store.state.projects.project_types"
                          :readonly="readOnly"
                          auto-select-first
                          autocomplete="off" item-text="name" item-value="id"
                          outlined/>
        </div>

        <div class="pa-md-4">

          <EditorField :readonly="readOnly" v-model="project.relevance" placeholder="Здесь можно писать"
                       description="Описание подходов к решению проблемы в мировой повестке, российской, областной, на уровне университета"
                       label="Актуальность"/>
          <EditorField :readonly="readOnly" v-model="project.auditory" placeholder="Здесь можно писать"
                       description="Характеристики целевой аудитории (социальные, экономические, поведенческие и т.д.). Подтвердите ссылками на исследования по данной целевой аудитории, результатами собственных опросов"
                       label="Целевая аудитория"/>
          <EditorField :readonly="readOnly" v-model="project.competitors" placeholder="Здесь можно писать"
                       description="Кто еще решает данную проблему? Каковы характеристики его решения? Чем оно лучше/слабее вашего?"
                       label="Конкуренты и аналоги"/>

          <EditorField :readonly="readOnly" v-model="project.novelty" placeholder="Здесь можно писать"
                       description="Чем ваше решение принципиально отличается от аналогов и конкурентов? Преимущества вашего решения"
                       label="Новизна"/>

          <EditorField :readonly="readOnly" v-model="project.risks" placeholder="Здесь можно писать"
                       description="Внешние и внутренние риски. Их оценка и меры по предотвращению"
                       label="Риски"/>

        </div>
        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Тип продукта</b>
          <v-combobox :readonly="readOnly"
                      outlined
                      style="border-radius: 10px; border-color: #efefef;"
                      :items="['изделие', 'приложение', 'мероприятие', 'модель', 'медиа ресурс']"
                      v-model="project.product_type"/>
        </div>

        <div class="pa-md-4">

          <EditorField :readonly="readOnly" v-model="project.product" placeholder="Здесь можно писать"
                       description="Опишите ваш продукт в виде ценностного предложения"
                       label="Результат/продукт"/>


          <EditorField :readonly="readOnly" v-model="project.measured_features" placeholder="Здесь можно писать"
                       description="Представьте не менее трех исчислимых показателей"
                       label="Ключевые характеристики продукта - Исчислимые"/>

          <EditorField :readonly="readOnly" v-model="project.not_measured_features" placeholder="Здесь можно писать"
                       description="Представьте не менее двух неисчислимых"
                       label="Ключевые характеристики продукта - Неисчислимые"/>

          <EditorField :readonly="readOnly" v-model="project.resources_and_costs" placeholder="Здесь можно писать"
                       description="Необходимые ресурсы, в том числе смета расходов"
                       label="Ресурсы"/>

          <EditorField :readonly="readOnly" v-model="project.resources_and_costs" placeholder="Здесь можно писать"
                       description="Источник и объемы доходов"
                       label="Доходы"/>

          <EditorField :readonly="readOnly" v-model="project.incomes" placeholder="Здесь можно писать"
                       description="Один – два основных канала продвижения с учётом поведения вашей целевой аудитории. Один запасной с учетом слабых сторон основных каналов"
                       label="Каналы продвижения"/>

          <EditorField :readonly="readOnly" v-model="project.partners" placeholder="Здесь можно писать"
                       description="Партнеры, в том числе заказчик проекта"
                       label="Партнеры"/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Достигнутый уровень</b>
          <v-combobox :readonly="readOnly"
                      outlined
                      style="border-radius: 10px; border-color: #efefef;"
                      :items="['Прототип', 'MVP', 'Готовый продукт']"
                      v-model="project.level"/>
        </div>

        <div class="pa-0 pa-md-4" style="border-bottom: 1px solid #eaeaea;">
          <b>Этап реализации</b>
          <v-combobox :readonly="readOnly"
                      outlined
                      style="border-radius: 10px; border-color: #efefef;"
                      :items="['Концепция', 'Апробирован', 'Доработан по результатам апробации', 'Подготовлен к продаже', 'Представлен на акселераторы, конкурсы, гранты']"
                      v-model="project.step"/>

        </div>

        <EditorField :readonly="readOnly" v-model="project.step_description" placeholder="Здесь можно писать"
                     description="ссылки, документы, примечания"
                     label="Этап реализации"/>

        <v-btn :loading="saveLoading" v-if="!readOnly" color="primary" dark elevation="0" class="mt-2" @click="save"
               rounded>Сохранить
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EditorField from "@/components/Projects/Utils/EditorField";

export default {
  name: "ProjectItemPassportComponent",
  components: {EditorField},
  computed: {
    readOnly() {
      return !this.$store.state.projects.currentProject.permissions.can_edit_info;
    },
  },
  data() {
    return {
      saveLoading: false,
      project: {
        relevance: null,
        auditory: null,
        competitors: null,
        novelty: null,
        risks: null,
        product: null,
        measured_features: null,
        not_measured_features: null,
        resources_and_costs: null,
        incomes: null,
        promotion_channels: null,
        partners: null,
      }
    }
  },
  methods: {
    save() {
      this.saveLoading = true;
      this.$store.dispatch('updateProject', this.project).then(() => {
        this.saveLoading = false
      });
    }
  },
  mounted() {
    this.project = {...this.project, ...this.$store.state.projects.currentProject};
  }
}
</script>

<style>

</style>
