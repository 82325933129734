<template>
  <div class="pa-0">
    <b>{{ label }}</b>
    <p v-if="description">{{ description }}</p>
    <div style="width: 100%; border: 1px solid #eaeaea; border-radius: 10px;" :id="this.hash(this.label+'_'+this.id)"></div>
  </div>

</template>

<script>
import EditorJS from '@editorjs/editorjs';
import editor from "@/util/editor";

export default {
  name: "EditorField",
  props: ['label', 'placeholder', 'description', 'value', 'readonly', 'id'],
  mounted() {
    // eslint-disable-next-line no-console
    console.log(this.value);
    this.editor = new EditorJS({
      ...editor, data: this.value, readOnly: this.readonly,  placeholder: this.placeholder, onChange: () => {
        this.save();
      }, holder: this.hash(this.label+'_'+this.id)
    });
  },
  methods: {
    hash(str) {
      return 'b' + str.split('').reduce((prevHash, currVal) =>
          (((prevHash << 5) - prevHash) + currVal.charCodeAt(0)) | 0, 0);
    },
    save() {
      this.editor.save().then((outputData) => {
         this.$emit('input', outputData);
      });
    }
  }
}
</script>

<style>
.codex-editor__redactor {
  padding: 0 !important;
  min-height: 100px;
}

.ce-block__content, .ce-toolbar__content {
  max-width: 100%;
  margin: 10px !important;
}

.cdx-block {
  max-width: 100% !important;
}

.ce-toolbox{
  z-index: 1000;
  position: absolute;
}

@media only screen and (max-width: 600px) {
  .ce-popover{
    bottom: 75px;
    background: white;
  }
}


</style>
