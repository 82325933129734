import { render, staticRenderFns } from "./ProjectItemPassportComponent.vue?vue&type=template&id=d56832e6&"
import script from "./ProjectItemPassportComponent.vue?vue&type=script&lang=js&"
export * from "./ProjectItemPassportComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VAutocomplete,VBtn,VCombobox,VTextField,VTextarea})
